<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>患者管理</el-breadcrumb-item>
      <el-breadcrumb-item>患者列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row>
        <!-- 搜索 -->
        <el-col :span="5">
          <el-input placeholder="患者姓名,手机号码" v-model="keywords" @click="Search" @keyup.enter.native="handleSearchMember">
            <el-button slot="append" icon="el-icon-search" @click="Search"></el-button>
          </el-input>
        </el-col>
        <!-- 筛选 -->
        <el-col :span="3">
          <div style="height:100%;margin-left:10px;">
            <button @click="showTime" class="filter-btn">
              <span>
                <svg width="15px" height="15px" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor" focusable="false" aria-hidden="true">
                  <defs>
                    <path d="M0 0h16v16H0z"></path>
                  </defs>
                  <path
                    d="M2.135 1.5a.5.5 0 00-.384.82l3.401 4.082c.225.27.348.61.348.96v4.02a.5.5 0 00.276.447l2 1a.5.5 0 00.724-.447v-5.02c0-.35.123-.69.348-.96l3.401-4.082a.5.5 0 00-.384-.82h-9.73zm0-1h9.73a1.5 1.5 0 011.152 2.46L9.616 7.042a.5.5 0 00-.116.32v5.02a1.5 1.5 0 01-2.17 1.342l-2-1a1.5 1.5 0 01-.83-1.342v-4.02a.5.5 0 00-.116-.32L.983 2.96A1.5 1.5 0 012.135.5z"
                    fill="#959EA6" fill-rule="nonzero">
                  </path>
                </svg>
              </span>
            </button>
          </div>
        </el-col>
        <!-- 发短信/导出/新增患者 -->
        <el-col :span="15" class="right">
          <!-- 发送短信 -->
          <div class="rights">
            <el-button type="primary" class="daochu_btn el-icon-message" style="color:#FFF;border:none;"
              @click="addMessage"><i style="margin-left:3px;font-style: normal;">发送短信</i>
            </el-button>
          </div>
          <div class="rights">
            <el-button class="daochu_btn" @click="removeOut"><i class="el-icon-upload2">导出</i></el-button>
          </div>
          <div class="left">
            <el-button @click="Addpatient">新增患者</el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height:40px;display: flex;align-items: center;">
        <div style="font-size: 12px;color: rgba(0, 0, 0, 0.85);">性别：</div>
        <div style="display:flex">
          <div @click=clickcCmmonfiltrate(item)
            style="font-size: 12px;margin: 0 5px;display: flex;align-items: center;justify-content: center;padding: 1px 3px;border-radius: 3px;"
            :class="item.show?'showCommon':'common'" v-for="(item,index) in filtrateList" :key="index">
            {{item.label}}
          </div>
        </div>
      </div>
      <div style="height:40px;display: flex;align-items: center;">
        <div style="font-size: 12px;color: rgba(0, 0, 0, 0.85);">近期就诊时间：</div>
        <div style="display:flex">
          <div @click=clickcDatefiltrate(item)
            style="font-size: 12px;margin: 0 5px;display: flex;align-items: center;justify-content: center;padding: 1px 3px;border-radius: 3px;"
            :class="item.show?'showCommon':'common'" v-for="(item,index) in filtrateDateList" :key="index">
            {{item.label}}
          </div>
        </div>
      </div>
      <div style="height:40px;display: flex;align-items: center;">
        <div style="font-size: 12px;color: rgba(0, 0, 0, 0.85);">项目：</div>
        <div>
          <el-select @change="commonCheckedCitiesChange" multiple size="mini" v-model="commonFiltrateProjectList"
            clearable placeholder="请选择项目类型">
            <el-option v-for="item2 in projectList" :key="item2.id" :label="item2.title" :value="item2.title">
            </el-option>
          </el-select>
        </div>
      </div>
      <div style="margin-top:0px;width:100%;height:1px;background-color:#e8e6e6"></div>
      <!-- 筛选标签 -->
      <div v-if="tags.length>0" id="tag"
        style="margin-top:5px;width:100%;height:30px;display: flex;align-items: center;">
        <div style="font-size:14px">筛选条件：</div>
        <el-tag @close="handleClose(tag)" size="medium" v-for="(tag,index) in tags" :key="index" closable
          :type="tag.type">
          {{tag.name}}
        </el-tag>
      </div>
      <!-- 新增患者 -->
      <el-dialog @close="cancel" v-if="showDialog" :title="dialogTitle" :visible.sync="showDialog" destroy-on-close width="65%">
        <div v-loading="addLoading">
          <!-- 手机号 -->
          <div class="name_content">
            <div class="name_content_tips"><i style="color:red;">*</i>{{phoneType?'手机号码:':'座机号码:'}}</div>
            <div style="position:relative">
              <input @input="phoneInputChange" style="width:200px" @blur="blur" @focus="focus" class="inss"
                :placeholder="phoneType?'请输入手机号码':'请输入座机号码'" v-model="editPhoneNumber" />
              <!-- 列表弹出层 -->
              <div class="inssList" v-loading="loading2">
                <el-collapse-transition>
                  <div v-show="list">
                    <div @click="currentItem(item)" class="item" v-for="(item,index) in options" :key="index"
                      style="width:100%">
                      <el-tooltip placement="top">
                        <div slot="content">创建时间：{{item.createTime}}<br />姓名：{{item.username}}<br />手机号：{{item.phone}}
                        </div>
                        <div style="display:flex;width:100%">{{item.username}}</div>
                      </el-tooltip>
                    </div>
                  </div>
                </el-collapse-transition>
              </div>
            </div>
            <el-tooltip placement="top">
              <div slot="content">点击可切换{{phoneType?'座机号码':'手机号码'}}</div>
              <el-button @click="phoneType=!phoneType" style="margin-left:20px" size="mini" circle
                icon="el-icon-refresh" type="info" round>
              </el-button>
            </el-tooltip>
            <div v-show="!phoneType" style="margin-left:20px;color:#ccc">座机格式：0563-13125321</div>
          </div>
          <!-- 身份证 -->
          <div class="name_content">
            <div class="name_content_tips"><i style="color:red;"></i>身份证:</div>
            <input class="inss" v-model="identityCard" type="text" placeholder="请输入身份证号" />
          </div>
          <!-- 性别 -->
          <div class="name_content">
            <div class="name_content_tips">性别:</div>
            <el-radio-group v-model="radio">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </div>
          <!-- 姓名 -->
          <div class="name_content">
            <div class="name_content_tips"><i style="color:red;">*</i>姓名:</div>
            <input class="inss" v-model="name" type="text" placeholder="请输入真实姓名" />
          </div>
          <!-- 年龄 -->
          <div class="name_content">
            <div class="name_content_tips">{{age?'生日:':'年龄:'}}</div>
            <!-- 生日 -->
            <el-date-picker value-format="yyyy-MM-dd" v-show="age" v-model="Select" type="date" placeholder="选择日期">
            </el-date-picker>
            <!-- 年龄 -->
            <input type="number" oninput="if(value>100)value=100" v-show="!age" style="width:200px" class="inss"
              placeholder="请输入年龄" v-model="ageForm" />
            <el-tooltip placement="top">
              <div slot="content">点击可切换{{age?'年龄':'生日'}}</div>
              <el-button @click="age=!age" style="margin-left:20px" size="mini" circle icon="el-icon-refresh"
                type="info" round>
              </el-button>
            </el-tooltip>
          </div>
          <!-- 病历 -->
          <div class="name_content">
            <!-- 添加病历 -->
            <div>
              <div class="name_content">
                <div style="margin-right:10px">添加病历</div>
                <el-switch v-model="addCaseHistory
              ">
                </el-switch>
              </div>
              <div v-if="addCaseHistory">
                <div class="name_content">
                  <div style="margin-right:10px"><i style="color:red;">*</i>医生列表</div>
                  <div>
                    <el-radio-group size="mini" v-model="currentDoctor">
                      <el-radio-button v-for="(item,index) in doctorList" :key="item.id" :label="item" :value="item">
                        {{item.name}}
                      </el-radio-button>
                    </el-radio-group>
                  </div>
                </div>
                <!-- 项目 -->
                <div id="projec" class="name_content">
                  <div class="name_content_tips"><i style="color:red;">*</i>项目:</div>
                  <el-checkbox-group size="medium" v-model="currentprojectList">
                    <el-checkbox v-for="(item,index) in projectList" :label="item.title" :key="index">
                      {{item.title}}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <!-- 口腔检查 -->
                <div class="name_content">
                  <div class="name_content_tips">口腔检查</div>
                  <div class="box1">
                    <div class="upper-toos">
                      <div class="fontsize">上</div>

                      <div class="upper">
                        <div class="tooth">
                          <el-checkbox-group size="mini" v-model="chooseType" @change="change">
                            <el-checkbox-button v-for="(item, index) in range" :label="item" :key="index">
                              {{ item.localTeeth }}
                            </el-checkbox-button>
                          </el-checkbox-group>
                        </div>
                        <div class="tooths">
                          <el-checkbox-group size="mini" v-model="chooseType" @change="change">
                            <el-checkbox-button v-for="(item, index) in range1" :label="item" :key="index">
                              {{ item.localTeeth }}
                            </el-checkbox-button>
                          </el-checkbox-group>
                        </div>
                      </div>
                      <div class="Lefts" style="display: flex;
                      align-items: center;position: relative;">
                        <span style="text-align:center">左</span>
                        <div class="Lefts_top"></div>
                        <span style="text-align:center">右</span>
                        <div class="Lefts-bottom" style="position:absolute;left: 50%;"></div>
                      </div>
                      <!-- <div class="fonsLef">
                        <div class="fontsizes">右</div>
                        <div class="fontsizes">左</div>
                      </div> -->
                      <div class="upper">
                        <div class="tooth">
                          <el-checkbox-group size="mini" v-model="chooseType" @change="change">
                            <el-checkbox-button v-for="(item, index) in range2" :label="item" :key="index">
                              {{ item.localTeeth }}
                            </el-checkbox-button>
                          </el-checkbox-group>
                        </div>
                        <div class="tooths">
                          <el-checkbox-group size="mini" v-model="chooseType" @change="change">
                            <el-checkbox-button v-for="(item, index) in range3" :label="item" :key="index">
                              {{ item.localTeeth }}
                            </el-checkbox-button>
                          </el-checkbox-group>
                        </div>
                      </div>
                      <div class="fontsize">下</div>
                    </div>
                    <div style="display:flex;justify-content:center;margin-top: 20px;">
                      <div style="position:relative">
                        <el-input id="focusInput" type="textarea" class="inputss" rows="3" cols="30" placeholder="请输入内容"
                          @focus="focusInput" @blur="blurInput" v-model="Oralexaminationeditor" name="kqjc">
                        </el-input>
                        <el-collapse-transition v-if="Oralexaminacontent.length">
                          <div v-if="Oralexaminashows" class="showscs inputss">
                            <ul>
                              <li class="focusLi" v-for="(item, index) in Oralexaminacontent" :key="index"
                                @click="Oralexaminaclick(item)">
                                {{ item.phrase }}
                              </li>
                            </ul>
                          </div>
                        </el-collapse-transition>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 时间： -->
                <div class="name_contents" style="align-items: center;display: flex;margin-top: 10px;">
                  <div class="name_content_tipes">
                    时间：
                  </div>
                  <el-date-picker v-model="addOrderTime" clearable type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="选择日期时间">
                  </el-date-picker>
                </div>
              </div>
            </div>
          </div>
          <!-- 收费单 -->
          <div class="name_content">
            <!-- 添加收费单 -->
            <div>
              <div class="name_content">
                <div style="margin-right:10px">添加收费单</div>
                <el-switch v-model="addCharge">
                </el-switch>
              </div>
              <div v-if="addCharge">
                <div class="name_content">
                  <div style="margin-right:10px;width:100px"><i style="color:red;">*</i>选择收费项目</div>
                  <div style="width:600px">
                    <!-- 收费 -->
                    <el-select value-key="id" size="medium" v-model="value5" placeholder="请选择收费项目" multiple>
                      <el-option v-for="(item) in cities5" :key="item.id" :label="item.moneyNames" :value="item">
                        <span style="float: left;">项目名称:{{ item.moneyNames }}</span>
                        <span style="float: right; color: #8492a6; font-size: 14px;margin-right: 20px;">{{
                          item.moneyAmounts
                          }}￥</span>
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <!-- 收费项目列表 -->
                <div style="width:100%;margin-top: 20px;min-height: 200px;overflow-y: auto;max-height:400px">
                  <el-table show-summary :summary-method="getNodeSummaries" size="mini" :data="value5"
                    style="width: 100%;border:1px solid #ccc">
                    <el-table-column label="项目名称">
                      <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.moneyNames }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="金额">
                      <template slot-scope="scope">
                        <el-tooltip v-if="scope.row.isLock==1" :content="scope.row.isLock==1?'金额已锁定':''"
                          placement="top">
                          <div>{{scope.row.moneyAmounts}}￥</div>
                        </el-tooltip>
                        <el-input-number @change="changeMoneyCounts(scope.row)" v-if="!scope.row.isLock" size="small"
                          v-model="scope.row.moneyAmounts"
                          onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))" :min="0" :max="99999999">
                        </el-input-number>
                      </template>
                    </el-table-column>
                    <el-table-column label="数量" width="200px">
                      <template slot-scope="scope">
                        <el-input-number size="small" v-model="scope.row.moneyCounts" :min="1" :max="999">
                        </el-input-number>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作">
                      <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
          <!-- 按钮 -->
          <div class="btn_content">
            <div class="btn_content_cancel" @click="cancel">取消</div>
            <div class="btn_content_confirm" @click="confirm">确定</div>
          </div>
        </div>
      </el-dialog>
      <!-- 列表区域 -->
      <el-table :height="'27rem'" ref="table" @select="Checkbox" @select-all="Checkbox" :data="userList"
        v-loading="loading" @row-click.self="bss">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column type="index" label="序号" width="70"></el-table-column>
        <el-table-column label="患者姓名" prop="username">
          <template slot="header" slot-scope="scope">
            <div @click="showTime($event,'0')">患者姓名 <i class="el-icon-menu"></i></div>
          </template>
          <template slot-scope="scope">
            <div>{{scope.row.username}}</div>
          </template>
        </el-table-column>
        <el-table-column label="性别" prop="gender">
          <template slot="header" slot-scope="scope">
            <div @click="showTime($event,'1')">性别 <i class="el-icon-menu"></i></div>
          </template>
          <template slot-scope="scope">
            <div>{{scope.row.gender}}</div>
          </template>
        </el-table-column>
        <el-table-column label="年龄" prop="age">
          <template slot="header" slot-scope="scope">
            <div @click="showTime($event,'2')">年龄 <i class="el-icon-menu"></i></div>
          </template>
          <template slot-scope="scope">
            <div>{{scope.row.age}}</div>
          </template>
        </el-table-column>
        <el-table-column label="电话" prop="phone">
          <template slot="header" slot-scope="scope">
            <div @click="showTime($event,'3')">电话 <i class="el-icon-menu"></i></div>
          </template>
          <template slot-scope="scope">
            <div>{{scope.row.phone}}</div>
          </template>
        </el-table-column>
        <el-table-column label="地址" prop="memberAddres">
          <template slot="header" slot-scope="scope">
            <div @click="showTime($event,'4')">地址 <i class="el-icon-menu"></i></div>
          </template>
          <template slot-scope="scope">
            <div>{{scope.row.memberAddres}}</div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime">
          <template slot="header" slot-scope="scope">
            <div style="display:flex">
              <div style="display:flex;align-items:center" @click="showTime($event,'5')">创建时间
              </div>
              <!-- 排序 -->
              <div style="display:flex;align-items:center;flex-direction:column;margin-left: 5px;">
                <span @click="keyDate('0')" style="line-height: 100%;">
                  <svg viewBox="0 0 1024 1024" height="12px" width="12px" :fill="topColor">
                    <path
                      d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z">
                    </path>
                  </svg>
                </span>
                <span @click="keyDate('1')" style="line-height: 100%;">
                  <svg viewBox="0 0 1024 1024" height="12px" width="12px" :fill="buttonColor">
                    <path
                      d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z">
                    </path>
                  </svg>
                </span>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <div>{{scope.row.createTime}}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <!-- 添加病例 -->
            <el-tooltip content="添加病例" placement="top" :enterable="false">
              <el-button type="warning" size="mini" icon="el-icon-circle-plus" @click.stop="addcase(scope.row)">
              </el-button>
            </el-tooltip>
            <!-- 添加收费单 -->
            <el-tooltip content="添加收费单" placement="top" :enterable="false">
              <el-button style="font-size:15px" type="info" size="mini" @click.stop="addcase2(scope.row)">
                ￥</el-button>
            </el-tooltip>
            <!-- 挂号 -->
            <el-tooltip content="挂号" placement="top" :enterable="false">
              <el-button type="success" size="mini" icon="el-icon-phone" @click.stop="register(scope.row)"></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <!-- <el-tooltip content="编辑" placement="top" :enterable="false">
              <el-button type="primary" size="mini" icon="el-icon-edit" @click.stop="edit(scope.row)"></el-button>
            </el-tooltip> -->
            <el-tooltip content="删除" placement="top" :enterable="false">
              <el-button type="danger" size="mini" icon="el-icon-delete" @click.stop="open(scope.row)"></el-button>
            </el-tooltip>
            <!-- <el-tooltip content="电子病历" placement="top" :enterable='false'>
                            <el-button type="info" size="mini" icon="el-icon-s-order" @click="watchRecord = true"></el-button>
                        </el-tooltip> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination style="margin: 15px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[10,20,100]" :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
      <!-- 短信弹出层 -->
      <el-dialog :center="true" title="编辑短信内容" :visible.sync="CaseDialog" :width="'700px'">
        <!-- 提示 -->
        <div style="color:#ccc;font-size: 14px;">
          <i style="color:red;font-size:14px;">*</i>
          短信最多可以发送1000字，正常短信是70个字(含字符),超出70个字的短信是长短信,每67个字计费一次。
        </div>
        <!-- 文本域 -->
        <div style="margin: 20px 0;"></div>
        <!-- <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 15 }" placeholder="请输入短信内容" v-model.trim="textarea"
          maxlength="1000" show-word-limit>
        </el-input> -->
        <el-select v-model="textarea" placeholder="请选择短信模板">
          <el-option v-for="item in template" :key="item.label" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <!-- 按钮 -->
        <div class="btn_content" style="justify-content:space-evenly">
          <div class="btn_content_cancel" style="margin-left:0px" @click="sendShortMessages">发送</div>
          <div class="btn_content_confirm" style="margin-left:0px" @click="clearMessage">取消</div>
        </div>
      </el-dialog>
      <!-- 筛选弹出层 -->
      <el-dialog @close="dialogClose" :center="true" title="设置筛选" :visible.sync="sxDialog" width="70">
        <div>
          <!-- 关键字 -->
          <!-- <div v-if="false" style="margin-bottom: 20px;">
                  <div style="display:flex;">
                    <div style="width:10%;font-weight: 600;display: flex;align-items: center;">关键字</div>
                    <div style="width: 20%;">
                      <el-select size="small" v-model="tabVal" clearable placeholder="请选择">
                        <el-option v-for="item in TabList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div style="margin:10px">-</div>
                    <div style="width: 20%;">
                      <el-select size="small" v-model="keywordVal" clearable placeholder="请选择类型">
                        <el-option v-for="item in keywordList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div style="margin:10px">-</div>
                    <div style="width: 20%;">
                      <el-input size="small" placeholder="请输入关键字" v-model="keywordInput" clearable>
                      </el-input>
                    </div>
                  </div>
                </div> -->
          <!-- 性别 -->
          <div v-if="false" style="display:flex;margin-bottom: 20px;">
            <div style="width:10%;font-weight: 600;display: flex;align-items: center;">性别</div>
            <div style="width: 50%;">
              <el-radio v-model="sexVal" label="1">男</el-radio>
              <el-radio v-model="sexVal" label="2">女</el-radio>
            </div>
          </div>
          <!-- 年龄 -->
          <div v-if="false" style="display:flex;margin-bottom: 20px;">
            <div style="width:10%;font-weight: 600;display: flex;align-items: center;">年龄</div>
            <div style="width: 30%;">
              <el-input size="small" placeholder="请输入起始年龄" v-model="startAge" clearable>
              </el-input>
            </div>
            <div style="margin:10px">-</div>
            <div style="width: 30%;">
              <el-input size="small" placeholder="请输入结束年龄" v-model="endAge" clearable>
              </el-input>
            </div>
          </div>
          <!-- 电话 -->
          <div v-if="false" style="display:flex;margin-bottom: 20px;">
            <div style="width:10%;font-weight: 600;display: flex;align-items: center;">电话</div>
            <div style="width: 30%;">
              <el-input size="small" placeholder="请输入号码" v-model="phoneVal" clearable>
              </el-input>
            </div>
            <div style="margin:10px">-</div>
            <div style="width: 30%;">
              <el-select size="small" v-model="phType" clearable placeholder="请选择类型">
                <el-option v-for="item in keywordList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <!-- 项目 -->
          <div v-if="false" style="display:flex;margin-bottom: 20px;">
            <div style="width:10%;font-weight: 600;display: flex;align-items: center;">项目</div>
            <div style="width: 80%;">
              <el-checkbox-group v-model="currentAddProjectList" @change="handleCheckedCitiesChange">
                <el-checkbox v-for="(item,index) in addProjectList" :label="item.title" :key="index">{{item.title}}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <!-- 日期 -->
          <div v-if="false" style="display:flex;margin-bottom: 20px;">
            <div style="width:10%;font-weight: 600;display: flex;align-items: center;">日期</div>
            <div style="width: 80%;">
              <el-date-picker size="mini" value-format="yyyy-MM-dd" v-model=" valueTime" type="daterange" align="left"
                unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                :picker-options="pickerOptions">
              </el-date-picker>
            </div>
          </div>
        </div>
        <!--且 -->
        <div v-for="(item,index) in currentFiltrateList" :key="index" style="margin: 35px 0;">
          <div style="display:flex;width:100%">
            <!-- icon/且 -->
            <div style="display:flex;align-items:center;margin-right: 20px;">
              <img @click="deleteAlso(item,index)" style="width: 30px;margin-right: 10px;"
                src="../../assets/Icon/icon_approve_reduce.png" alt="">
              <span>且</span>
            </div>
            <div style="width:100%">
              <!-- 行 -->
              <div v-for="(it,ind) in item" :key="ind"
                style="display: flex;position: relative;height: 60px;align-items: center;width:100%">
                <div style="width: 1px;height: 60px;background: #ddd;position: absolute;top: 0%;left: 0;"></div>
                <div style="height: 1px;width: 20px;background: #ddd;position: absolute;top: 0%;left: 1;"></div>
                <div style="margin-left: 19px;position: absolute;width: 100%;top: -25%;display: flex;">
                  <!-- 默认第一个 -->
                  <div style="width:25%;margin-right: 10px;">
                    <el-select @change="changeProject($event,it)" size="small" v-model="it.itemObj" clearable
                      placeholder="请选择">
                      <el-option v-for="item in TabList" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <!-- 默认第二个 -->
                  <div v-if="it.itemObj!=5 && it.itemObj!=1 && it.itemObj!=6 && it.itemObj!=2"
                    style="width:25%;margin-right: 10px;">
                    <el-select size="small" v-model="it.objType" clearable placeholder="请选择类型">
                      <el-option v-for="item in keywordList" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <!-- 默认第三个 -->
                  <div v-if="it.itemObj!=5 && it.itemObj!=1 && it.itemObj!=6 && it.itemObj!=2"
                    style="width:25%;margin-right: 10px;">
                    <el-input size="small" placeholder="请输入关键字" v-model="it.inpputData" clearable>
                    </el-input>
                  </div>
                  <!-- 创建时间 -->
                  <div v-if="it.itemObj==5" style="width:40%;margin-right: 10px;display:flex;align-items: center;">
                    <el-date-picker v-if="it.itemObj==5" size="mini" value-format="yyyy-MM-dd" v-model="it.inpputData"
                      type="daterange" align="left" unlink-panels range-separator="至" start-placeholder="开始日期"
                      end-placeholder="结束日期" :picker-options="pickerOptions">
                    </el-date-picker>
                  </div>
                  <!-- 性别 -->
                  <div v-if="it.itemObj==1" style="width:25%;margin-right: 10px;">
                    <el-select size="small" v-model="it.inpputData" clearable placeholder="请选择性别">
                      <el-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.label">
                      </el-option>
                    </el-select>
                  </div>
                  <!-- 项目 -->
                  <div v-if="it.itemObj==6" style="width:40%;margin-right: 10px;">
                    <el-select multiple size="small" v-model="it.inpputData" clearable placeholder="请选择项目类型">
                      <el-option v-for="item2 in projectList" :key="item2.id" :label="item2.title" :value="item2.title">
                      </el-option>
                    </el-select>
                  </div>
                  <!-- 年龄 -->
                  <div v-if="it.itemObj==2 && it.itemObj!=1 && it.itemObj!=6"
                    style="width:50%;margin-right: 10px;display:flex;align-items:center">
                    <el-input v-model="startAge" size="small" placeholder="请输入开始年龄" clearable>
                    </el-input>
                    <div style="margin:0 10px">-</div>
                    <el-input @focus="focus2($event,it)" @change="ageBlur($event,it)" :disabled="startAge?false:true"
                      v-model="endAge" size="small" placeholder="请输入结束年龄" clearable>
                    </el-input>
                  </div>
                  <div style="display:flex;align-items:center;">
                    <img @click="deleteLine(item,index,it,ind)"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAABzklEQVQ4T92Tv2tTURzFz7kmrxFjBjMZCMm7T0FUKKjgFnDQ4CBYRFwcRAfBVRC6OfkfONRdqDg4iFRaBOdixEno8O59ImiE2ooO4QnvHnmlLa2NcXHyO34593O/vw4xJiRVvPezAG4COAjgRa1Wu9tqtVZ/l3McwHv/IIQwS3IJwBdJ1wAsW2t7JMPON3sAkoxzbp3korX2ailO0/QOgIeVSuVMp9MZ7AI45+Ylnd5KktwnKSb5VdL6Zn4KQJvkJwA/trSSBnTOzUk6B+Bo+RnJ7+Pa2pHbL+kYyQ8AljZacM6dl7RI8oK1tuz7j+Gcm5b0DsD1JEke/3tAHMevvfcrAGYAnJXUT5LkSpqmr0jOAViZWEEURW/zPF81xpSAXgihBJxwzn0uNwHg+f8OkLQM4BuASyR7APrW2uk0TT+WMyC58Nc1Oudu1ev1J3meHy6K4ki3213IsmymWq2+yfP80B5AlmWniqIYkLxtrX006ZC895dDCM+MMRfjOH65cUiSprz3maQ1kvdJ/hwHKX0SQrgn6XgURd12u7227UbvfT+E8HTT/5OKyI0xN+I4ni9Fu+w8HA4PjEajk8aYyjhCCKFoNBrvm83mtuF+AVZMVpMJN9oXAAAAAElFTkSuQmCC"
                      alt="">
                  </div>
                </div>
              </div>
              <!-- icon/添加行 -->
              <div style="display: flex;position: relative;align-items: center;width:100%;"
                :style="(JSON.stringify(item)=='{}')?'top: 50% !important':''">
                <div style="height: 1px;width: 20px;background: #ddd;position: absolute;top: 0%;left: 1;"></div>
                <div style="margin-left: 19px;position: absolute;width: 100%;display: flex;margin-left: 28px;">
                  <img @click="addFiltrateListItemLine(index)" style="width: 30px;"
                    src="../../assets/Icon/icon_approve_add.png" alt="">
                </div>
              </div>
            </div>
          </div>
          <div v-if="false"
            style="height: 20px;width: 100%;position: relative;display: flex;align-items: center;margin-top: 20px;top:50%">
            <div style="border-bottom: 1px solid #ddd;height: 10px;width: 100%;"></div>
            <span
              style="display: block;background: #fff;width: 40px;line-height: 30px;position: absolute;top: 0;left: 50%;right: 50%;bottom: 0;margin: auto;text-align: center;">或</span>
          </div>
        </div>
        <!-- 增加筛选器 -->
        <div v-if="false" style="display:flex;margin-bottom: 20px;height: 40px;align-items: center;">
          <img @click="addFiltrate" style="vertical-align: middle;border-style: none;width: 30px;height: 30px;"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAYAAAAfrhY5AAACn0lEQVRYR8WXX2iNcRjHP99zcmGuJNO2uDGUcKNcoGRRKCs3u2OFbRHCjc15T8l7ZnNFSO1PS9ztgpoyRaRwIW6Q8u+GnGWSK1vSPPq970475+xw3o3tfS7f9/k93+fv9/f8RFTJWA1GPWIrxhJENcYCxFeMLOIDxh3EAJ4+RTGrskrttplf+Ij1GIX6YhRjboENYRiPSZAmpft/s/9n8A6rZYwLGNsDA+IzcBMYwHhFJUO0aIQuq2CYKsRKoB7YibFo/MwgSY7QpnelnCgN7tsWRD/GfCALnGIFfTRorGym+i3Ja/YGZ6Aa8Q2jgbTuFp+dDO7bPkQXRhLRzUKOBRFOVVxGvnAOoxkxhmgmpb58M4XgYcS3xxWO4unSVDEn6WfsEHA++G5sy8/ABHhY4ydBqsXh/wKc88Q5YFwMSpBkXa4HJsAzNhh45lLtqeWfIy42kDFXSleCQTztCHvYSThO94LmqmRZpBqbibOs4gQvkayss+FUvA2aMEGdG8MQ3LeHwAYSNJFSb1lDTiFjjRhXSNBISlcjnfGtCegGHpHWRuGYCz4CwyynJtI4heCtGB2INjx1RgJ3Y/gGx36VwGLh2wHgMqIXT86zaDId8NDpHoz9wEEX+XWMXYh6PDkGiybTB3cMOIC44SJ/CqwFaknrfTTkaaY97K+lgKPbZy7yLEYV86jguEYngbuubmcPUFX0b9P4aDpSelDwT2Q5ybWSUxB2/XfEkIv8R0B/nipKRt1pq/nJ88gZySnOYQ2telHyXMZGHH1Hi/wMuzGqZyLyWGsea7fHOucxMlw+t0MzafVE6uz/wu2x32oh594KlsVZv88deKybTFj7mHa4XJfFtr1OOBDT3p5zILYXS/6g+1YHnJ7dt1ox08zAK/U3yxadjDWp/JIAAAAASUVORK5CYII="
            alt="">
          <div @click="addFiltrate"
            style="display:flex;align-items:center;font-size: 12px;margin:0 10px;color:#0083ff;width: 100px;">
            增加筛选器
          </div>
        </div>
        <!-- 按钮 -->
        <div class="btn_content">
          <div style="flex:1;">
            <div style="background-color:orange" class="btn_content_cancel" @click="clear">重置</div>
          </div>
          <div style="flex:1;display: flex;justify-content: center;">
            <div class="btn_content_confirm" @click="search">确认</div>
          </div>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
  import { Debounce } from '../../utils/index'
  import { parseTime } from '../../utils/index.js'
  import { nanoid } from 'nanoid';
  import { number } from 'echarts/lib/export';
  export default {
    data() {
      return {
        identityCard: '',//身份证号
        buttonColor: '',// 颜色
        topColor: '#1890ff',// 颜色
        value5: [],// 选中项目
        cities5: [], // 收费列表
        addCharge: false,// 添加收费单开关
        addOrderTime: "",// 添加患者病历时间
        Oralexaminashows: false /* 口腔检查隐藏内容 */,
        Oralexaminationeditor: "",/* 口腔内容 */
        Oralexaminacontent: [] /* 口腔快捷短语 */,
        range: [
          { value: 1, localTeeth: "8", choose: 0 },
          { value: 2, localTeeth: "7", choose: 0 },
          { value: 3, localTeeth: "6", choose: 0 },
          { value: 4, localTeeth: "5", choose: 0 },
          { value: 5, localTeeth: "4", choose: 0 },
          { value: 6, localTeeth: "3", choose: 0 },
          { value: 7, localTeeth: "2", choose: 0 },
          { value: 8, localTeeth: "1", choose: 0 },
        ],
        range1: [
          { value: 9, localTeeth: "1", choose: 1 },
          { value: 10, localTeeth: "2", choose: 1 },
          { value: 11, localTeeth: "3", choose: 1 },
          { value: 12, localTeeth: "4", choose: 1 },
          { value: 13, localTeeth: "5", choose: 1 },
          { value: 14, localTeeth: "6", choose: 1 },
          { value: 15, localTeeth: "7", choose: 1 },
          { value: 16, localTeeth: "8", choose: 1 },
        ],
        range2: [
          { value: 1, localTeeth: "8", choose: 3 },
          { value: 2, localTeeth: "7", choose: 3 },
          { value: 3, localTeeth: "6", choose: 3 },
          { value: 4, localTeeth: "5", choose: 3 },
          { value: 5, localTeeth: "4", choose: 3 },
          { value: 6, localTeeth: "3", choose: 3 },
          { value: 7, localTeeth: "2", choose: 3 },
          { value: 8, localTeeth: "1", choose: 3 },
        ],
        range3: [
          { value: 9, localTeeth: "1", choose: 4 },
          { value: 10, localTeeth: "2", choose: 4 },
          { value: 11, localTeeth: "3", choose: 4 },
          { value: 12, localTeeth: "4", choose: 4 },
          { value: 13, localTeeth: "5", choose: 4 },
          { value: 14, localTeeth: "6", choose: 4 },
          { value: 15, localTeeth: "7", choose: 4 },
          { value: 16, localTeeth: "8", choose: 4 },
        ],
        chooseType: [] /* 多选 */,
        localTeh: "",/*用于存储牙齿信息*/
        element: "",
        c: [],
        currentAddProjectList: [],// 添加患者项目选中列表
        addProjectList: [],// 添加患者项目列表
        currentDoctor: '',// 选中医生
        doctorList: '',// 医生列表
        addCaseHistory: false,// 添加病历开关
        addFiltrateType: '',// 添加筛选器类型
        addFiltrateData: '',// 添加筛选器tab
        commonFiltrateList: [],//常用筛选选中列表
        commonFiltrateProjectList: [],// 常用筛选项目列表
        // 常用性别筛选列表
        filtrateList: [{
          value: '0',
          label: '女',
          show: false,
        }, {
          value: '1',
          label: '男',
          show: false,
        }],
        // 常用日期筛选列表
        filtrateDateList: [],
        currentDateArr: [],// 常用日期筛选选中列表
        // tab项列表
        TabList: [{
          value: '0',
          label: '患者姓名',
        }, {
          value: '1',
          label: '性别',
        }, {
          value: '2',
          label: '年龄',
        }, {
          value: '3',
          label: '电话',
        }, {
          value: '4',
          label: '地址',
        }, {
          value: '5',
          label: '近期就诊时间',
        }, {
          value: '6',
          label: '项目',
        }],// Tab列表
        FiltrateSelect: false,// 筛选器类型选择框显示
        currentFiltrateList: [], // 筛选显示列表
        checkFiltrateList: [],// 筛选选中列表
        startAge: '',// 起始年龄
        endAge: '',//结束年龄
        ageCurrentItem: '',// 年龄选中项
        tabVal: '',// tab选中项
        filtrateType: '',// 关键字筛选类型选中项
        phType: '',// 电话筛选类型选中项
        phoneVal: '',// 码号
        sexVal: '',// 性别
        currentSexArr: [],// 常用筛选选中列表
        keywordInput: '',// 关键输入字内容
        // 关键字类型选择列表
        keywordList: [{
          value: '0',
          label: '包含'
        }, {
          value: '1',
          label: '不包含'
        }, {
          value: '2',
          label: '开始于'
        }, {
          value: '3',
          label: '结束于'
        }],
        // 关键字类型选择列表
        sexList: [{
          value: '0',
          label: '男'
        }, {
          value: '1',
          label: '女'
        }],
        // 关键字类型选中
        keywordVal: '',
        // 标签列表
        tags: [],
        // 短信模板
        template: [{
          value: '0',
          label: '尊敬的牙如意用户 ${name},您好！为了您的口腔健康着想，请你有空关注${place}的复诊哦。祝您的生活愉快！',
        }],
        textarea: '',// 选中短信模板
        // 筛选日期选项
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        valueTime: '',// 日期筛选
        projectList: [],// 项目筛选列表
        commonCurrentProjectList: [],// 常用筛选项目选中列表
        currentprojectList: [],// 项目筛选选中列表
        sxDialog: false,// 筛选弹出层
        CheckboxList: [],// 单选框列表
        addLoading: false,// 添加加载状态
        de: new Debounce(500),
        loading2: false,//列表加载状态
        list: '',// 列表开关
        options: [],
        age: true,// 年龄切换开关
        ageForm: '',// 年龄
        phoneType: true,// 手机号码或座机号码
        keywords: "",
        loading: false,
        page: 1,
        limit: 10,
        total: 0,
        userList: [],
        showDialog: false,
        radio: 1,
        dialogTitle: "添加患者",
        name: "",
        Select: "",
        timeSpace: "",
        editPhoneNumber: "",
        CaseDialog: false,// 发送短信弹框
        // 电子病历数据
        dianziRecord: [
          {
            timestamp: "2020-01-02",
            docterName: "阿卜杜拉",
            type: "拔牙",
            Info: "注意事项，注意什么事项，注意事项",
            size: "large",
            icon: "el-icon-more",
            color: "#636dec",
          },
          {
            timestamp: "2020-01-02",
            docterName: "阿卜杜拉",
            type: "拔牙",
            Info: "注意事项，注意什么事项，注意事项",
          },
          {
            timestamp: "2020-01-02",
            docterName: "阿卜杜拉",
            type: "拔牙",
            Info: "注意事项，注意什么事项，注意事项",
          },
        ],
        currentPage: 1,
        editVisible: false, //编辑用户对话框
        watchRecord: false, //查看点子病历
        userInfo: {
          name: "",
          gender: "",
          phoneNum: "",
          id: "",
          age: "",
        },
        // 编辑用户表单验证规则
        editRules: {
          name: [
            { required: true, message: "请输入患者姓名", trigger: "blur" },
            { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
          ],
          gender: [
            { required: true, message: "请输入性别", trigger: "blur" },
            { min: 1, max: 1, message: "请输入正确性别", trigger: "blur" },
          ],
          phoneNum: [
            { required: true, message: "请输入手机号码", trigger: "blur" },
            { min: 11, max: 11, message: "请输入正确手机号码", trigger: "blur" },
          ],
          id: [
            { required: true, message: "请输入患者ID", trigger: "blur" },
            { min: 11, max: 11, message: "长度位11位", trigger: "blur" },
          ],
          age: [
            { required: true, message: "请输入年龄", trigger: "blur" },
            { min: 1, max: 3, message: "长度在 1 到 3 个字符", trigger: "blur" },
          ],
        },
        hospitalId: "",
        num: 0,// 选中收费总金额
      };
    },
    created() {
      function getDate(date) {
        var day1 = new Date();
        var s2 = day1.getFullYear() + "-" + (day1.getMonth() + 1) + "-" + day1.getDate();
        day1.setTime(day1.getTime() - (24 * Number(date)) * 60 * 60 * 1000);
        var s1 = day1.getFullYear() + "-" + (day1.getMonth() + 1) + "-" + day1.getDate();
        return [s2, s1]
      }
      this.filtrateDateList = [{
        value: '0',
        label: '昨天',
        show: false,
        startTime: getDate(1)[1],
        endTime: getDate(1)[0],
      }, {
        value: '1',
        label: '近3天',
        show: false,
        startTime: getDate(3)[1],
        endTime: getDate(3)[0],
      }, {
        value: '2',
        label: '近7天',
        show: false,
        startTime: getDate(7)[1],
        endTime: getDate(7)[0],
      }, {
        value: '3',
        label: '近30天',
        show: false,
        startTime: getDate(30)[1],
        endTime: getDate(30)[0],
      }]
      this.InfoList();
      this.watchDetail()
    },
    mounted() {
      this.fetch()
      this.watchDetail()
      this.Oralexaminamuns()
      this.getList()
    },
    watch: {
      addCharge(newVal, oldVal) {
        if (!newVal) {
          this.value5 = []
        }
      },
      addCaseHistory(newVal, oldVal) {
        if (!newVal) {
          this.currentDoctor = ''
          this.currentprojectList = []
          this.chooseTypes = []
          this.chooseType = []
          this.Oralexaminationeditor = ''
          this.addOrderTime = ''
        }
      },
      endAge(newVal, oldVal) {
        if (newVal > this.startAge) {
          let str = `${this.startAge},${newVal}`
          console.log(this.ageCurrentItem)
          this.ageCurrentItem.inpputData = str
        }
      },
      checkFiltrateList(newVal, oldVal) {
        if (this.commonFiltrateProjectList.length <= 0) return
        if (newVal.length <= 0) return
        this.commonCurrentProjectList = []
        let str = this.commonFiltrateProjectList.join(',')
        str = `项目包含${str}`
        let arr = []
        for (let i = 0; i < this.currentFiltrateList.length; i++) {
          let item = this.currentFiltrateList[i]
          let obj = {}
          for (let key in item) {
            if (!item[key].inpputData) continue;
            obj[key] = item[key]
            let index1 = item[key].itemObj
            let name1 = this.TabList[Number(index1)].label
            let index2 = item[key].objType
            let name2 = this.keywordList[Number(index2)].label
            let str2 = name1 + name2 + item[key].inpputData
            arr.push(
              { name: str2, type: '', filtrateListIndex: i, filtrateListItemLineKey: key }
            )
          }
        }
        this.commonCurrentProjectList.push(
          { name: str, type: '', filtrateListIndex: '', filtrateListItemLineKey: 'project', item: { inpputData: this.commonFiltrateProjectList, itemObj: '6' } }
        )
        this.tags = [...arr, ...this.commonCurrentProjectList, ...this.currentSexArr]
      },
      tags(newVal, oldVal) {
        this.page = 1
      }
    },
    computed: {
      userAge() {
        if (this.age) {
          return this.Select
        } else {
          let date = parseTime(new Date().getTime(), "{y}")
          date = Number(date) - Number(this.ageForm)
          let str = `${date}-01-01 00:00:00`
          return str
        }
      }
    },
    methods: {
      // 排序
      keyDate(type) {
        if (type === '0') {
          this.buttonColor = ''
          this.topColor = '#1890ff'
          this.page = 1
        } else if (type === '1') {
          this.topColor = ''
          this.buttonColor = '#1890ff'
          this.page = 1
        }
        this.$http.post(`member/timeSort?integer=${type}`).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            for (let i = 0; i < res.data.data.list.length; i++) {
              if (res.data.data.list[i].gender === "1") {
                res.data.data.list[i].gender = "男";
              } else if (res.data.data.list[i].gender === "2") {
                res.data.data.list[i].gender = "女";
              } else {
                res.data.data.list[i].gender = "未知";
              }
            }
            this.total = res.data.data.count;
            this.userList = res.data.data.list;
          }
        }).catch(err => {
          this.$message.error(res.data.msg)
        })
      },
      changeProject(e, item) {
        item.inpputData = ''
      },
      // 年龄结束输入框聚焦
      focus2(e, item) {
        console.log(item)
        this.ageCurrentItem = item
      },
      // 年龄结束输入框失焦
      ageBlur(e, item) {
        if (e < this.startAge) {
          this.$message.error('结束年龄必须大于开始年龄')
          this.endAge = ''
          this.ageCurrentItem.inpputData = ''
        } else {
          item.inpputData = `${this.startAge}至${this.endAge}`
          item.startAge = this.startAge
          item.endAge = this.endAge
        }
      },
      // 选中收费项目删除
      handleDelete(index, row) {
        this.value5.splice(index, 1);
      },
      // 改变默认金额
      changeMoneyCounts(item) {
        item.actualMoney = item.moneyAmounts
      },
      // 合计金额
      getNodeSummaries(param) {
        console.log(param)
        const { columns, data } = param;
        const sums = [];
        let num = 0;
        for (let i = 0; i < data.length; i++) {
          num += Number(data[i].moneyAmounts) * Number(data[i].moneyCounts)
        }
        columns.forEach((column, index) => {
          if (index == columns.length - 2) {
            sums.push('总金额:');
            return;
          } else if (index == columns.length - 1) {
            sums.push(`${num.toFixed(3)}￥`);
            return;
          } {
            sums.push(null);
          }
        })
        this.num = num
        return sums
      },
      getList() {
        this.$http.post(`/fees/list`, null, {
          params: {
            size: 9,
          }
        }).then(res => {
          console.log(111, res)
          if (res.data.code == 200) {
            this.cities5 = res.data.data.records
            console.log(this.cities5)
          } else {
            if (res.data.msg) {
              this.$message.error(res.data.msg)
            }
          }
        })
      },
      Oralexaminaclick(item) {
        this.Oralexaminationeditor = item.phrase;
        this.Oralexaminashows = false;
      },
      Oralexaminamuns() {
        this.$http
          .post(`/phrase/select`, {
            oneCatalogue: 1,
            twoCatalogue: 3,
          })
          .then((res) => {
            this.Oralexaminacontent = res.data.data;
          });
      },
      // 聚焦事件
      focusInput(e) {
        console.log(e.srcElement.name)
        switch (e.srcElement.name) {
          case 'zs':
            this.shows = true;
            break;
          case 'xbs':
            this.Presenshows = true;
            break;
          case 'kqjc':
            this.Oralexaminashows = true;
            break;
          case 'zlfa':
            this.Governanshows = true;
            break;
          case 'yz':
            this.Medishows = true;
            break;
        }
      },
      // 失焦事件
      blurInput(e) {
        console.log(e.srcElement.name)
        switch (e.srcElement.name) {
          case 'zs':
            this.shows = false;
            break;
          case 'xbs':
            this.Presenshows = false;
            break;
          case 'kqjc':
            this.Oralexaminashows = false;
            break;
          case 'zlfa':
            this.Governanshows = false;
            break;
          case 'yz':
            this.Medishows = false;
            break;
        }
      },
      change(e) {
        console.log(e);
        this.localTeh = e;
      },
      // 获取项目列表
      watchDetail() {
        this.$http
          .post("/doctor/getProjectById", {
            id: 999,
          })
          .then((res) => {
            console.log(res);
            if (res.data.code = 200) {
              this.projectList = res.data.data
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 获取医生列表
      fetch() {
        this.$http.post(`/doctor/selectDoctor`, {
          limit: 10,
          page: 1
        }).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.doctorList = res.data.data;
          }
        });
      },
      // 选中常用性别筛选
      clickcCmmonfiltrate(item) {
        console.log(item)
        for (let i = 0; i < this.filtrateList.length; i++) {
          if (this.filtrateList[i].label != item.label) {
            this.filtrateList[i].show = false
          }
        }
        item.show = !item.show
        console.log(item)
        if (item.show) {
          this.currentSexArr = []
          let str = `性别包含${item.label}`
          this.currentSexArr.push(
            { name: str, type: '', filtrateListIndex: '', filtrateListItemLineKey: 'sex', item: { inpputData: item.label, itemObj: '1' } }
          )
          for (let i = 0; i < this.tags.length; i++) {
            if (this.tags[i].filtrateListItemLineKey == 'sex') {
              this.tags.splice(i, 1)
            }
          }
          console.log(this.currentSexArr)
          this.tags = [...this.tags, ...this.currentSexArr]
          if (this.tags.length > 0) {
            this.search()
          } else {
            this.InfoList();
          }
        } else {
          this.currentSexArr = []
          for (let i = 0; i < this.tags.length; i++) {
            if (this.tags[i].filtrateListItemLineKey == 'sex') {
              this.tags.splice(i, 1)
              if (this.tags.length > 0) {
                this.search()
              } else {
                this.InfoList();
              }
              return
            }
          }
        }
      },
      // 选中常用日期筛选
      clickcDatefiltrate(item) {
        console.log(item)
        for (let i = 0; i < this.filtrateDateList.length; i++) {
          if (this.filtrateDateList[i].label != item.label) {
            this.filtrateDateList[i].show = false
          }
        }
        item.show = !item.show
        console.log(item)
        if (item.show) {
          this.currentDateArr = []
          let str = `近期就诊日期包含${item.label}`
          this.currentDateArr.push(
            { name: str, type: '', filtrateListIndex: '', filtrateListItemLineKey: 'date', item: { inpputData: [item.startTime, item.endTime], itemObj: '5' } }
          )
          for (let i = 0; i < this.tags.length; i++) {
            if (this.tags[i].filtrateListItemLineKey == 'date') {
              this.tags.splice(i, 1)
            }
          }
          console.log(this.currentDateArr)
          this.tags = [...this.tags, ...this.currentDateArr]
          if (this.tags.length > 0) {
            this.search()
          } else {
            this.InfoList();
          }
        } else {
          this.currentDateArr = []
          for (let i = 0; i < this.tags.length; i++) {
            if (this.tags[i].filtrateListItemLineKey == 'date') {
              this.tags.splice(i, 1)
              if (this.tags.length > 0) {
                this.search()
              } else {
                this.InfoList();
              }
              return
            }
          }
        }
      },
      // 弹出层关闭回调
      dialogClose() {
        this.filtInputfiltrate()
        // if (this.checkFiltrateList.length > 0) {
        //   this.currentFiltrateList = JSON.parse(JSON.stringify(this.checkFiltrateList))
        // }
        // console.log(this.currentFiltrateList)
      },
      // 添加筛选行
      addFiltrateListItemLine(index) {
        let id = nanoid()
        this.$set(this.currentFiltrateList[index], id, {
          itemObj: '',
          objType: '',
          inpputData: '',
        })
      },
      // 删除且
      deleteAlso(item, index) {
        this.currentFiltrateList.splice(index, 1)
      },
      // 删除行
      deleteLine(item, index, it, ind) {
        let obj = this.currentFiltrateList[index]
        delete obj[ind]
        this.$set(this.currentFiltrateList, index, obj)
        console.log(this.currentFiltrateList)
      },
      // 取消添加筛选器
      clearaddFiltrateData() {
        this.addFiltrateType = ''
        this.addFiltrateData = ''
        this.FiltrateSelect = false
      },
      // 添加筛选器
      addFiltrate() {
        let id = nanoid()
        this.currentFiltrateList.push({
          [id]: { itemObj: '', objType: '', inpputData: '' }
        })
      },
      // 去除标签
      handleClose(tag) {
        this.tags.splice(this.tags.indexOf(tag), 1);
        if (tag.filtrateListItemLineKey == 'project') {
          this.commonFiltrateProjectList = []
          if (this.tags.length > 0) {
            this.search()
          } else {
            this.InfoList();
          }
          return
        }
        if (tag.filtrateListItemLineKey == 'sex') {
          this.currentSexArr = []
          for (let i = 0; i < this.filtrateList.length; i++) {
            this.filtrateList[i].show = false
          }
          if (this.tags.length > 0) {
            this.search()
          } else {
            this.InfoList();
          }
          return
        }
        if (tag.filtrateListItemLineKey == 'date') {
          this.currentDateArr = []
          for (let i = 0; i < this.filtrateDateList.length; i++) {
            this.filtrateDateList[i].show = false
          }
          if (this.tags.length > 0) {
            this.search()
          } else {
            this.InfoList();
          }
          return
        }
        let index = tag.filtrateListIndex
        let id = tag.filtrateListItemLineKey
        let obj = this.checkFiltrateList[index]
        delete obj[id]
        this.$set(this.checkFiltrateList, index, obj)
        let obj2 = this.currentFiltrateList[index]
        delete obj2[id]
        this.$set(this.currentFiltrateList, index, obj2)
        if (this.tags.length > 0) {
          this.search()
        } else {
          this.InfoList();
        }
      },
      // 重置筛选条件
      clear() {
        this.checkFiltrateList = []
        this.currentFiltrateList = []
        this.tags = []
        this.commonFiltrateList = []
        for (let i = 0; i < this.filtrateList.length; i++) {
          this.filtrateList[i].show = false
        }
      },
      // 过滤未输入筛选器
      filtInputfiltrate() {
        let list = []
        let arr = []
        console.log(this.currentFiltrateList)
        for (let i = 0; i < this.currentFiltrateList.length; i++) {

          let item = this.currentFiltrateList[i]
          let obj = {}
          for (let key in item) {
            if (!item[key].inpputData) continue;
            obj[key] = item[key]
            let index1 = item[key].itemObj
            let name1 = this.TabList[Number(index1)].label
            let index2 = item[key].objType
            let name2 = this.keywordList[Number(index2)].label
            let str = name1 + name2 + item[key].inpputData
            arr.push(
              { name: str, type: '', filtrateListIndex: i, filtrateListItemLineKey: key, item: JSON.parse(JSON.stringify(item[key])) }
            )
          }
          list[i] = obj
        }
        console.log(list)
        this.currentFiltrateList = list
        this.checkFiltrateList = list
        this.tags = [...arr, ...this.commonCurrentProjectList, ...this.currentSexArr]
        return arr
      },
      // 筛选搜索
      search() {
        this.sxDialog = false
        this.$nextTick(() => {
          let orderInfoList = []
          let arr = [...this.tags]
          console.log(arr)
          for (let i = 0; i < arr.length; i++) {
            let item = arr[i]
            // 患者姓名
            if (arr[i].item && arr[i].item.itemObj === '0') {
              orderInfoList.push({
                patientName: arr[i].item.inpputData,//项目关键字
                checkKind: arr[i].item.objType,// 0.包含1.不包含2.起始于3.结束于
              })
              console.log(orderInfoList)
            }
            // 性别
            else if (arr[i].item && arr[i].item.itemObj === '1') {
              orderInfoList.push({
                sex: arr[i].item.inpputData === '男' ? 1 : 2,//1.男2.女
              })
            }
            // 年龄
            else if (arr[i].item && arr[i].item.itemObj === '2') {
              orderInfoList.push({
                age: arr[i].item.startAge,//开始年龄
                endAge: arr[i].item.endAge,//开始年龄
              })
            }
            // 电话
            else if (arr[i].item && arr[i].item.itemObj === '3') {
              orderInfoList.push({
                memberPhone: arr[i].item.inpputData,//电话关键字
                checkKind: arr[i].item.objType,// 0.包含1.不包含2.起始于3.结束于
              })
            }
            // 地址
            else if (arr[i].item && arr[i].item.itemObj === '4') {
              orderInfoList.push({
                memberAddres: arr[i].item.inpputData,//地址关键字
                checkKind: arr[i].item.objType,// 0.包含1.不包含2.起始于3.结束于
              })
            }
            // 创建时间
            else if (arr[i].item && arr[i].item.itemObj === '5') {
              orderInfoList.push({
                startTime: arr[i].item.inpputData[0] + ' 23:59:59',//开始时间
                endTime: arr[i].item.inpputData[1] + ' 23:59:59',//结束时间
              })
            }
            // 项目
            else if (arr[i].item && arr[i].item.itemObj === '6') {
              orderInfoList.push({
                treatmentName: arr[i].item.inpputData.join(',')// 项目
              })
            }
          }
          console.log(orderInfoList)
          this.loading = true;
          this.$http.post('member/checkPatient', {
            orderInfoList,
            page: {
              current: this.page,
              size: this.limit
            }
          }
          ).then(res => {
            if (res.data.code == 200) {
              console.log(res)
              for (let i = 0; i < res.data.data.list.length; i++) {
                if (res.data.data.list[i].gender === "1") {
                  res.data.data.list[i].gender = "男";
                } else if (res.data.data.list[i].gender === "2") {
                  res.data.data.list[i].gender = "女";
                } else {
                  res.data.data.list[i].gender = "未知";
                }
              }
              this.total = res.data.data.count;
              this.userList = res.data.data.list;
              this.sxDialog = false
              this.loading = false;
            } else {
              this.$message.error(res.data.msg)
              this.loading = false;
            }
          })
        })
      },
      handleCheckedCitiesChange(value) { },
      // 常用筛选项目改变
      commonCheckedCitiesChange(value) {
        let list = []
        let arr = []
        if (value.length > 0) {
          this.commonCurrentProjectList = []
          let str = value.join(',')
          str = `项目包含${str}`
          for (let i = 0; i < this.currentFiltrateList.length; i++) {
            let item = this.currentFiltrateList[i]
            for (let key in item) {
              if (!item[key].inpputData) continue;
              let index1 = item[key].itemObj
              let name1 = this.TabList[Number(index1)].label
              let index2 = item[key].objType
              let name2 = this.keywordList[Number(index2)].label
              let str2 = name1 + name2 + item[key].inpputData
              arr.push(
                { name: str2, type: '', filtrateListIndex: i, filtrateListItemLineKey: key }
              )
            }
          }
          this.commonCurrentProjectList.push(
            { name: str, type: '', filtrateListIndex: '', filtrateListItemLineKey: 'project', item: { inpputData: this.commonFiltrateProjectList, itemObj: '6' } }
          )
        } else {
          this.commonCurrentProjectList = []
        }
        let array = [...arr, ...this.commonCurrentProjectList, ...this.currentSexArr]
        this.tags = array
        if (this.tags.length > 0) {
          this.search()
        } else {
          this.InfoList();
        }
      },
      // 获取项目列表
      watchDetail() {
        this.$http
          .post("/doctor/getProjectById", {
            id: 999,
          })
          .then((res) => {
            console.log(res);
            if (res.data.code = 200) {
              this.projectList = res.data.data
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 过滤选项
      showTime(e, type = '') {
        this.sxDialog = true
        if (JSON.stringify(this.currentFiltrateList) === "[]" || JSON.stringify(this.currentFiltrateList) === '[{}]') {
          let id = nanoid()
          this.currentFiltrateList = [{
            [id]: { itemObj: type, objType: '', inpputData: '' },
          }]
        }
      },
      // 改变选项
      Checkbox(e) {
        console.log(e)
        this.CheckboxList = e
      },
      // 发送短信
      sendShortMessages() {
        if (this.textarea) {
          let arr = [];
          for (let i = 0; i < this.CheckboxList.length; i++) {
            if (this.CheckboxList[i].phone) {
              let obj = {}
              obj.phone = this.CheckboxList[i].phone
              obj.username = this.CheckboxList[i].username
              obj.companyName = this.CheckboxList[i].companyName
              arr.push(obj)
            }
          }
          this.$http.post('member/sendMess', arr).then(res => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg);
              this.$refs['table'].clearSelection();
              this.CheckboxList = []
              this.CaseDialog = false;
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          this.$message.error('请选择短信模板')
        }
      },
      // 取消发送短信
      clearMessage() {
        this.CaseDialog = false
      },
      // 短信弹框
      addMessage() {
        if (this.CheckboxList.length == 0) {
          this.$message.error('请选择用户')
          return
        }
        this.CaseDialog = true
      },
      // 弹框提示
      open(item) {
        this.$confirm('此操作将永久删除该患者, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(() => {
          this.del(item)
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
      },
      // 点击选中
      currentItem(item) {
        this.editPhoneNumber = item.phone
        this.name = item.username
      },
      // 模糊搜索
      phoneInputChange() {
        if (this.editPhoneNumber && this.editPhoneNumber.length > 2) {
          this.loading2 = true
          this.de.debounceEnd().then(res => {
            this.$http.post(`user/selectByPhone`, {}, {
              params: {
                phone: this.editPhoneNumber// 手机号
              }
            }).then(res => {
              console.log(res)
              if (res.data.code == 200) {
                this.options = res.data.data
                this.loading2 = false
              } else {
                if (res.data.msg) {
                  this.$message.error(res.data.msg)
                }
              }
            })
          })
        }
      },
      // 聚焦
      focus() {
        this.list = true
      },
      // 失焦
      blur() {
        this.list = false
      },
      handleSearchMember() {
        this.$http
          .post(`/member/selectAll`, {
            username: this.keywords,
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              if (res.data.data.length === 0) {
                this.$message.warning("暂无匹配结果");
              } else {
                this.total = res.data.count;
                this.userList = res.data.data;
              }
            }
          });
      },
      bss(e) {
        console.log(e);
        this.$router.push({
          path: "/guanli/usersMangeEdit",
          query: {
            Info: e,
          },
        });
      },
      cancel() {
        console.log(1)
        this.showDialog = false;
        this.editPhoneNumber = ''
        this.identityCard=''
        this.addCaseHistory=false
        this.currentDoctor=''
        this.currentprojectList=[]
        this.chooseType=[]
        this.c=[]
        this.Oralexaminationeditor=''
        this.addOrderTime=""
        this.addCharge=false
        this.value5=[]
        this.Select = ''
        this.name = ''
        this.radio = 1
        this.phoneType = true
        this.ageType = true
        this.ageForm = ''
        this.localTeh=''
        this.options = []
      },
      //新增患者
      confirm() {
        if (
          this.editPhoneNumber &&
          this.name !== ""
        ) {
          if (this.addCaseHistory) {
            for (let i = 0; i < this.localTeh.length; i++) {
              this.element = this.localTeh[i];
              this.$set(this.element, "oralCavity", this.Oralexaminationeditor);
              this.c.push(this.element);
            }
            console.log(this.c)
            if (!this.currentDoctor || this.currentprojectList.length <= 0) {
              this.$message.error("添加病历信息填写不完善");
              return
            }
          }
          if (this.addCharge) {
            if (this.value5.length <= 0) {
              this.$message.error("添加收费单信息填写不完善");
              return
            }
          }
          let rule = this.phoneType ?
            /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/ : /^((\d{3,4}\-)|)\d{7,8}(|([-\u8f6c]{1}\d{1,5}))$/
          if (rule.test(this.editPhoneNumber)) {
            this.addLoading = true
            let obj = {}
            if (this.c.length > 0) {
              obj.localTeethList2 = this.c
            }
            obj.patientInfo = {
              phone: this.editPhoneNumber,
              username: this.name,
            }
            if (this.radio) {
              obj.patientInfo.gender = this.radio
            }
            if (this.userAge) {
              obj.patientInfo.birth = this.userAge
            }
            if (this.addCaseHistory) {
              obj.medical = {
                memberPhone: this.editPhoneNumber,// 手机号
                patientName: this.name,// 患者姓名
              }
              obj.orderInfo = {
                treatmentName: this.currentprojectList.join(','),// 选中项目
                patientName: this.name,
                doctorName: this.currentDoctor.name /* 医生名称 */,
                doctorId: this.currentDoctor.id /* 医生id */,
                orderStatus: "3",
                category: "1",
                orderTime: this.addOrderTime ? this.addOrderTime : parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}')
              }
            }
            if (this.addCharge) {
              for (let i = 0; i < this.value5.length; i++) {
                if (this.value5[i].actualMoney === null) {
                  this.value5[i].actualMoney = this.value5[i].moneyAmounts
                }
              }
              console.log(this.value5)
              obj.hsChargeOrders = {
                hsFeesList: this.value5,// 选中收费项目
                totalMoney: this.num.toFixed(3),// 总金额
              }
            }
            console.log(obj)
            // return
            this.$http
              .post("/member/addPatient", obj)
              .then((res) => {
                console.log(res)
                if (res.data.code === 200) {
                  this.addLoading = false
                  this.$message.success("添加患者成功");
                  this.InfoList();
                  this.showDialog = false;
                  this.ageForm = ''
                  this.radio = 1
                  this.editPhoneNumber = ''
                  this.name = ''
                  this.localTeh=[]
                  this.cancel()
                } else {
                  this.$message.error(res.data.msg)
                  this.showDialog = false;
                  this.addLoading = false
                }
              });
          } else {
            this.$message.error(this.phoneType ? "手机号码格式不正确" : "座机号码格式不正确");
          }
        } else {
          this.$message.error("提交信息不完善");
        }
      },
      //添加患者
      Addpatient() {
        this.showDialog = true;
      },
      // 列表数据
      InfoList() {
        this.loading = true;
        this.$http
          .post("/member/selectAll", {
            page: this.page,
            limit: this.limit,
          })
          .then((res) => {
            if (res.data.code == 200) {
              console.log(res);
              for (let i = 0; i < res.data.data.length; i++) {
                if (res.data.data[i].gender === "1") {
                  res.data.data[i].gender = "男";
                } else if (res.data.data[i].gender === "2") {
                  res.data.data[i].gender = "女";
                } else {
                  res.data.data[i].gender = "未知";
                }
              }
              this.total = res.data.count;
              this.userList = res.data.data;
              this.loading = false;
            }
          });
      },
      Search() {
        this.$http
          .post(`/member/selectAll`, {
            username: this.keywords,
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              if (res.data.data.length === 0) {
                this.$message.warning("暂无匹配结果");
              } else {
                this.total = res.data.count;
                this.userList = res.data.data;
              }
            }
          });
        // if (this.keywords.length == 11) {
        //   this.$http
        //     .post(`/member/selectAll`, {
        //       phone: this.keywords,
        //     })
        //     .then((res) => {
        //       console.log(res);
        //       if (res.data.code == 200) {
        //         if (res.data.data.length == 0) {
        //           this.$message.warning("暂无匹配结果");
        //         } else {
        //           this.total = res.data.count;
        //           this.userList = res.data.data;
        //         }
        //       }
        //     });
        // } else {
        //   this.$http
        //     .post(`/member/selectAll`, {
        //       username: this.keywords,
        //     })
        //     .then((res) => {
        //       console.log(res);
        //       if (res.data.code == 200) {
        //         if (res.data.data.length === 0) {
        //           this.$message.warning("暂无匹配结果");
        //         } else {
        //           this.total = res.data.count;
        //           this.userList = res.data.data;
        //         }
        //       }
        //     });
        // }
      },
      //分页
      handleSizeChange(newSize) {
        this.limit = newSize;
        console.log(this.limit);
        this.$nextTick(() => {
          if (this.tags.length > 0) {
            this.search()
          } else {
            this.InfoList();
          }
        })
      },
      handleCurrentChange(newPage) {
        this.page = newPage;
        console.log(this.page);
        if (this.tags.length > 0) {
          this.search()
        } else {
          this.InfoList();
        }
      },
      edit(e) {
        console.log(e)
        this.$router.push({
          path: "/guanli/usersMangeEdit",
          query: {
            Info: e,
          },
        });
      },
      addcase(e) {
        this.$router.push({
          path: "/guanli/Addcase",
          query: {
            Info: e
          },
        });
      },
      addcase2(e) {
        this.$router.push({
          path: "/guanli/Addcase",
          query: {
            Info: e, type: 'telephone'
          },
        });
      },
      register(e) {
        this.$router.push({
          path: "/guanli/UserManageRegister",
          query: {
            Info: e,
          },
        });
      },
      // 导出
      removeOut() {
        this.$http
          .post(`/member/exportExcel`, { page: this.page, limit: this.limit, }, { responseType: "blob", })
          .then((res) => {
            console.log(res);
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([res.data]));
            link.target = "_blank";
            //文件名和格式
            link.download = "列表.xlsx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      },
      // 删除所选列表
      delList(id) {
        console.log(id);
        this.$http.delete(`/member/branchDelete/${id}`).then((res) => {
          if (res.data.code == 200) {
            this.$message.success(res.data.msg);
            this.InfoList();
          } else {
            this.$message.error("删除失败");
          }
        });
      },
      // 删除接口
      del(info) {
        console.log(info);
        this.delList(info.id);
      },
    },
  };
</script>

<style scoped lang='less'>
  /deep/.el-textarea__inner {
    max-height: 400px;
  }

  /deep/.el-table {
    margin-top: 0;
  }

  /deep/#tag .el-tag--medium {
    margin-right: 5px;
    height: 1.55rem;
    line-height: 1.525rem;
  }

  #focusInput:focus {
    border: 1px solid #bbb;
    outline: none;
  }

  .name_content_tipes {
    width: 100px;
  }

  .filter-btn {
    width: 40px;
    height: 40px;
    border-radius: 3px;
    border: 1px solid #ccc;
    background-color: #fff;
    font-size: 12px;
  }

  .fontsize {
    color: #232323;
    font-size: 16px;
    text-align: center;
  }

  .icons {
    font-size: 25px;
  }

  .upper {
    margin-top: 15px;
    color: #b3b0b7;
    display: flex;
    justify-content: space-around;
  }

  .inputss {
    position: relative;
    width: 400px;
    font-size: 14px;
    /* margin-top: 25px;
    margin-left: 20%; */
    border-radius: 5px;
    word-break: break-all;
  }

  .focusLi {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 3px;
  }

  .showscs::-webkit-scrollbar {
    width: 0 !important
  }

  .showscs {
    z-index: 999;
    position: absolute;
    background-color: #fff;
    margin-top: 3px;
    font-size: 14px;
    height: auto;
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ccc;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }

  .tooth {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .tooths {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 25px;
  }

  .Lefts {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .Lefts_top {
    width: 600px;
    height: 1px;
    background-color: #dcdfe6;
    margin: auto;
  }

  .fonsLef {
    display: flex;
    justify-content: space-between;
  }

  .fontsizes {
    color: #232323;
    font-size: 16px;
    text-align: center;
    padding: 0px 15px;
  }

  .Lefts-bottom {
    width: 1px;
    height: 110px;
    background-color: #dcdfe6;
  }

  .common {
    background-color: #fff;
    border: 1px solid #ccc;
    color: rgba(0, 0, 0, 0.85);
    box-sizing: border-box;
  }

  .name_content_tips {
    width: 100px;
  }

  .showCommon {
    border: 1px solid #FFF;
    background-color: #0083ff;
    color: #fff;
    box-sizing: border-box;
  }

  .filter-btn:focus {
    outline: 0;
  }

  .filter-btn:hover {
    border-color: #0083ff;
    border-radius: 3px;
    fill: #0083ff
  }

  .filter-btn:hover svg path {
    fill: #0083ff
  }


  /deep/.el-checkbox {
    margin-top: 5px;
  }

  /deep/.el-picker-panel .el-date-range-picker .el-popper .has-sidebar {
    left: 25% !important;
  }

  .inssList {
    width: 100%;
    max-height: 200px;
    position: absolute;
    z-index: 999;
    background-color: #fff;
    color: #666;
    font-size: 16px;
    overflow-y: auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    box-sizing: border-box;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }

  .inssList::-webkit-scrollbar {
    width: 0 !important
  }

  .inss {
    width: 200px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #eee;
  }

  .item {
    padding-left: 10px;
    height: 40px;
    align-items: center;
    box-sizing: border-box;
    display: flex;
  }

  .item:hover {
    background-color: #f5f7fa;
    height: 40px;
    align-items: center;
    box-sizing: border-box;
    display: flex;
  }

  .right {
    display: flex;
    justify-content: flex-end;
  }

  .daochu_btn {
    border: 1px solid #666ee8;
    color: #666ee8;
    margin-right: 40px;
  }

  .name_content {
    // padding: 0 0 0 30px;
    display: flex;
    align-items: center;
    padding-top: 20px;
    // margin-top: 35px;
  }

  .name_content_tips {
    // color: #232323;
    // font-size: 26px;
    // margin-right: 50px;
    // font-weight: bold;
    margin-right: 20px;
  }

  .inss {
    border-bottom: 1px solid #dddddd;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    outline: none;
  }

  .btn_content {
    display: flex;
    margin-top: 25px;
  }

  .btn_content_confirm {
    margin-left: 80px;
    width: 60px;
    height: 40px;
    background-color: #666ee8;
    color: aliceblue;
    text-align: center;
    line-height: 40px;
    border-radius: 20%;
  }

  .btn_content_cancel {
    margin-left: 150px;
    background-color: #666ee8;
    width: 60px;
    height: 40px;
    color: aliceblue;
    text-align: center;
    line-height: 40px;
    border-radius: 20%;
  }
</style>